<template>
  <DetailsDrawerSection title="Wochenbericht">

    <template v-slot:quick-actions>
      <v-btn
        v-if="canWriteWeekReport"
        icon
        small
        :disabled="loading"
        @click="showEditWeekReportDialog()"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>

    <LessonTextField :value="text" />

    <EditWeekReportDialog
      v-if="canWriteWeekReport"
      :week-report="editWeekReport"
      @save:done="onSaveWeekReport"
      @close="editWeekReport = null"
    />

  </DetailsDrawerSection>
</template>


<script>
import { api } from '@/api'
import { getWeekOfYear } from '@/helpers/datetime'
import DetailsDrawerSection from '@/pages/schedule.page/details-drawer/details-drawer-section'
import LessonTextField from '@/components/lesson-text-field'
import EditWeekReportDialog from '@/components/edit-week-report-dialog'

export default {
  name: 'WeekReportSection',
  components: {
    DetailsDrawerSection,
    LessonTextField,
    EditWeekReportDialog
  },
  props: {
    actualLesson: Object,
    loading: Boolean
  },
  data: () => ({
    weekReport: null,
    editWeekReport: null
  }),
  computed: {
    canWriteWeekReport() {
      return this.$store.getters['canWriteWeekReport']
    },
    schoolClass() {
      return this.actualLesson?.scheduledLesson?.schoolClass || null
    },
    schoolSubject() {
      return this.actualLesson?.scheduledLesson?.schoolSubject || null
    },
    calendarWeekCode() {
      const date = this.actualLesson?.date
      return date && getWeekOfYear(date).toString() || null
    },
    text() {
      return this.weekReport?.text
    }
  },
  watch: {
    actualLesson() {
      this.loadWeekReport()
    }
  },
  created() {
    this.loadWeekReport()
  },
  methods: {
    async loadWeekReport() {
      this.weekReport = null
      const { schoolClass, schoolSubject, calendarWeekCode } = this
      if (!(schoolClass && schoolSubject && calendarWeekCode)) return
      const params = {pagination: false, schoolClass, schoolSubject, calendarWeekCode}
      const response = await api.get('/api/week_reports', {params})
      this.weekReport = response.data['hydra:member']?.[0] || null
    },
    showEditWeekReportDialog() {
      this.editWeekReport = this.weekReport || {
        schoolClass: this.schoolClass,
        schoolSubject: this.schoolSubject,
        calendarWeekCode: this.calendarWeekCode,
        text: ''
      }
    },
    async onSaveWeekReport(weekReport) {
      this.weekReport = weekReport
      this.editWeekReport = null
    }
  }
}
</script>
