<template>
  <div
    class="component rounded-pill text-truncate px-3"
    :style="{background}"
    v-text="text"
  />
</template>


<script>
export default {
  name: 'SchoolSubjectChip',
  props: {
    schoolSubject: {
      type: Object,
      required:true
    }
  },
  computed: {
    text() {
      return this.schoolSubject?.name
    },
    background() {
      return this.schoolSubject?.color
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/scss/variables.scss';

.component {
  font-size: 14px !important;
  background: $default-school-subject-color;
  cursor: default;
}
</style>
