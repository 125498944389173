<template>
  <v-dialog
    persistent
    scrollable
    max-width="1024"
    :value="show"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card v-if="show">

      <v-card-title class="subtitle-1 justify-center">

        <v-icon class="mr-4">mdi-clipboard-text-outline</v-icon>

        <div class="text-sm-h6">Wochenbericht</div>

        <div class="week-title flex-grow-1">
          {{ week.text }}
          <small>
            ({{ week.startsOn | formatDate }} - {{ week.endsOn | formatDate }})
          </small>
        </div>

        <SchoolSubjectChip :school-subject="schoolSubject" />

      </v-card-title>

      <v-divider/>

      <v-card-text class="pt-4">
        <v-textarea
          auto-grow
          outlined
          dense
          rows="4"
          counter="1000"
          :disabled="loading"
          :rules="[length(1000)]"
          label="Bericht"
          v-model="text"
        />
      </v-card-text>

      <v-card-title
        v-if="error"
        class="justify-center"
      >
        <v-alert
          dense
          outlined
          type="error"
        >
          {{ error }}
        </v-alert>
      </v-card-title>

      <v-divider/>

      <v-card-actions class="px-6">
        <v-spacer />
        <v-btn
          text
          :disabled="loading"
          v-text="hasChanges ? 'Abbrechen' : 'Schließen'"
          @click="$emit('close')"
        />
        <v-btn
          v-show="hasChanges"
          color="primary"
          :loading="loading"
          @click="save"
        >
          Schließen
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>


<script>
import { mapGetters } from 'vuex'
import { api, getErrorReason } from '@/api'
import { formatDate } from '@/helpers/datetime'
import { length } from '@/helpers/validators'
import SchoolSubjectChip from '@/components/school-subject-chip'

export default {
  name: 'EditWeekReportDialog',
  components: {
    SchoolSubjectChip
  },
  filters: {
    formatDate
  },
  props: {
    weekReport: {
      type: Object
    },
  },
  data: () => ({
    length,
    text: '',
    loading: false,
    error: null
  }),
  computed: {
    ...mapGetters('schoolCalendar', ['weeksBySection']),
    show() {
      return !!this.weekReport
    },
    week() {
      const { section } = this.$store.state.schoolCalendar
      const weeks = this.$store.getters['schoolCalendar/weeksBySection'](section)
      const weekId = this.weekReport?.calendarWeekCode
      return weeks.find(w => w.weekId === weekId)
    },
    schoolSubject() {
      return this.$store.getters['common/schoolSubjectByIri'](this.weekReport?.schoolSubject)
    },
    hasChanges() {
      return this.show && this.text !== this.weekReport.text
    }
  },
  watch: {
    show() {
      this.resetForm()
    }
  },
  methods: {
    resetForm() {
      this.error = null
      this.text = this.show && this.weekReport.text || ''
    },
    async save() {
      if (!this.hasChanges) {
        return
      }
      const iri = this.weekReport['@id']
      const method = iri ? 'put' : 'post'
      const patch = {text: this.text}
      const payload = iri ? patch : {
        ...this.weekReport,
        ...patch
      }
      this.error = null
      this.loading = true
      try {
        const weekReport = (await api[method](iri || '/api/week_reports', payload)).data
        this.$emit('save:done', weekReport)
      } catch (e) {
        console.error(e)
        this.error = getErrorReason(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.week-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 16px;
  font-size: small;
}
</style>
